import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Hero } from '../components/hero'
import { TeaserEvents } from '../components/events/teaser-events'
import { getOrganizedEventData } from '../utils/helpers'
import { EurolandIframe } from '../components/euroland'
import { Highlight } from '../components/text/highlight'
import Layout from '../layouts'
import { SEO } from '../components/seo'

export default function BlogRoute(props: PageProps) {
  const newsPage = props.data.newsPage
  const eventsPage = props.data.eventsPage
  const teaserEventsAsc = props.data.teaserEventsAsc.edges
  const teaserEventsDesc = props.data.teaserEventsDesc.edges

  const upcomingEvents = getOrganizedEventData(teaserEventsAsc, 'future')
  const pastEvents = getOrganizedEventData(teaserEventsDesc, 'past')

  return (
    <Layout location={props.location}>
      <div className="text-gray-700 bg-white dark:bg-black dark:text-gray-300">
        {newsPage.hero && (
          <Hero
            heros={newsPage.hero}
            fallbackTitle={newsPage.title}
            position="5"
          />
        )}
        <div id="news-blog" className="wrapper">
          <div className="container relative flex flex-col py-20 mx-auto md:flex-row">
            <div className="w-full h-full mb-8 md:w-2/3 lg:mb-0">
              <div className="md:pr-8">
              <div className="text-left mb-4">
                <Highlight className="dark:text-white">Regulatory News</Highlight>
              </div>
                <EurolandIframe
                  lightUrl="https://tools.eurolandir.com/tools/pressreleases/?companycode=us-umg&v=light&lang=en-GB"
                  darkUrl="https://tools.eurolandir.com/tools/pressreleases/?companycode=us-umg&v=dark&lang=en-GB"
                  adjustHeight
                />
              </div>
            </div>
            <div className="w-full h-full md:w-1/3">
              <div className="mb-6">
                <TeaserEvents
                  title="Upcoming Events"
                  isUpcomingEvent
                  needButton={false}
                  eventData={upcomingEvents}
                  noResults={eventsPage.noResults.noResults}
                />
              </div>
              <div>
                {pastEvents.length > 0 && (
                  <TeaserEvents
                    title="Past events"
                    isUpcomingEvent={false}
                    needButton={false}
                    eventData={pastEvents}
                    noResults="<h1>No Past Events</h1>"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query newsPageQuery {
    newsPage: contentfulCustomPage(slug: { eq: "news" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      noResults {
        noResults
      }
      hero {
        ...HeroFragment
      }
    }
    teaserEventsAsc: allContentfulEvent(
      filter: { shown: { eq: true } }
      sort: { fields: [sticky, date], order: [DESC, ASC] }
    ) {
      edges {
        node {
          title
          location
          locationLinkText
          duration
          overrideDateBehavior
          date
          endDate
          startDateTime: date(formatString: "YYYYMMDDTHHmmssZ")
          endDateTime: endDate(formatString: "YYYYMMDDTHHmmssZ")
        }
      }
    }
    teaserEventsDesc: allContentfulEvent(
      filter: { shown: { eq: true } }
      sort: { fields: [sticky, date], order: [DESC, DESC] }
    ) {
      edges {
        node {
          title
          location
          duration
          overrideDateBehavior
          date
          endDate
          startDateTime: date(formatString: "YYYYMMDDTHHmmssZ")
          endDateTime: endDate(formatString: "YYYYMMDDTHHmmssZ")
        }
      }
    }
    eventsPage: contentfulCustomPage(slug: { eq: "events" }) {
      noResults {
        noResults
      }
    }
  }
`
